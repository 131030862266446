import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TheTopMenu = _resolveComponent("TheTopMenu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (!_ctx.connectionStatus)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Ожидание подключения к WebSocket "))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_component_TheTopMenu),
        _createVNode(_component_router_view)
      ]))
}