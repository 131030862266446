
import { useRoute } from 'vue-router';
import { MainMutationsNames, useStore } from '@/store';
import { computed, defineComponent, watch } from 'vue';
import sendWS from '@/websocket';
import Line from '@/components/item/Line.vue';

export default defineComponent({
  name: 'Home',
	components: {
		Line,
	},
  setup() {
		const route = useRoute();

		const store = useStore();

		const itemLoad = computed(() => (store.state.main.itemLoad));

		const itemData = computed(() => (store.state.main.itemData));

		watch(() => (route.params.id), (newValue) => {
			if (Array.isArray(newValue)) return;
			if (newValue) {
				store.commit(MainMutationsNames.SetItemLoad, true);
				setTimeout(() => {
					sendWS({ event: 'getItem', data: Number(newValue) });
				}, (store.state.main.idAll.length) ? 0 : 2000);
			}
		}, {
			immediate: true,
		});

		return {
			itemLoad,
			itemData,
		};
	},
});
