
import {
	computed,
	defineComponent,
} from 'vue';
import { MainMutationsNames, useStore } from '@/store';
import { EventTypeVariant } from '@/types';
import sendWS from '@/websocket';

export default defineComponent({
  name: 'TopMenu',
	setup() {
		const store = useStore();

		const list = computed(() => ({
			...store.state.main.event,
			idAll: store.state.main.idAll,
			errorCodeAll: store.state.main.errorCodeAll,
		}));

		const listVariant = [{
			id: 'idAll',
			text: 'Кампании',
		}, {
			id: 'errorCodeAll',
			text: 'Коды ошибок',
		}, {
			id: 'system',
			text: 'Системные сообщения',
		}, {
			id: 'player',
			text: 'Сообщения плеера',
		}, {
			id: 'vpaid',
			text: 'Сообщения vpaid',
		}, {
			id: 'media',
			text: 'Сообщения media',
		}, {
			id: 'toSdk',
			text: 'Сообщения в SDK',
		}];

		// const selectMessage: Ref<SortValue[]> = ref([]);
		const selectSort = computed(() => (store.state.main.selectSort));

		const clickButton = (variant: EventTypeVariant, code: string) => {
			store.commit(MainMutationsNames.ClickSort, { variant, code });
		};

		const reloadData = () => {
			store.commit(MainMutationsNames.SetNowLoad, true);
			sendWS({ event: 'getList', data: { sort: selectSort.value } });
		};

		const firstLoadData = computed(() => (store.state.main.firstLoadData));
		const nowLoadData = computed(() => (store.state.main.nowLoadData));

		const sortResult = computed(() => (store.state.main.sortResult));

		return {
			list,
			listVariant,
			clickButton,
			selectSort,
			reloadData,
			firstLoadData,
			nowLoadData,
			sortResult,
		};
	},
});
