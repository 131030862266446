import type { MainState } from './types';

const mainState: MainState = {
	connectionStatus: false,
	event: {
		system: [],
		toSdk: [],
		player: [],
		vpaid: [],
		media: [],
	},
	idAll: [],
	errorCodeAll: [],
	selectSort: [],
	firstLoadData: false,
	nowLoadData: false,
	sortResult: [],

	itemLoad: false,
	itemData: [],
};

export default mainState;
