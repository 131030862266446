
import type { GetItemResultValue } from '@/types';
import {
  computed,
	defineComponent, PropType, toRefs,
} from 'vue';
import { useStore } from '@/store';
import { EVENT_TYPE_VARIANT } from '@/const';

export default defineComponent({
	props: {
		line: {
			type: Object as PropType<GetItemResultValue>,
			required: true,
		},
		i: {
			type: Number,
			required: true,
		},
		firstData: {
			type: Number,
			required: true,
		},
	},
	setup(props) {
		const { line, firstData, i } = toRefs(props);
		const store = useStore();

		const classTitle = computed(() => {
			const e = EVENT_TYPE_VARIANT.find((el) => (store.state.main.event[el].includes(line.value.event)));
			return e || '';
		});

		const printData = computed(() => {
			if (i.value) {
				const value = line.value.timestamp - firstData.value;
				return (value > 500) ? '' : value;
			}
			const date = new Date(line.value.timestamp * 1000);
			const hours = (date.getHours().toString().length === 1) ? `0${date.getHours()}` : date.getHours();
			const minutes = (date.getMinutes().toString().length === 1) ? `0${date.getMinutes()}` : date.getMinutes();
			const seconds = (date.getSeconds().toString().length === 1) ? `0${date.getSeconds()}` : date.getSeconds();
			return `${hours}:${minutes}:${seconds}`;
		});

		const json = computed(() => {
			if (![
				'ut-player-start',
				'ut-player-init',
				'ut-player-ad-init',
				'ut-player-ad-vast-fetch',
				'ut-player-ad-vast-fetch-url',
				'ut-player-ad-vast-fetch-result',
				'catch-vast-parser',
			].includes(line.value.event)) return false;
			try {
				const v = JSON.parse(line.value.value);
				return v;
			} catch (e) {
				return false;
			}
		});

		return {
			classTitle,
			printData,
			json,
		};
	},
});
