import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "item__container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Line = _resolveComponent("Line")!

  return (_ctx.itemLoad)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Загрузка данных "))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemData, (item, index) => {
          return (_openBlock(), _createBlock(_component_Line, {
            key: index,
            line: item,
            i: index,
            firstData: _ctx.itemData[0].timestamp
          }, null, 8, ["line", "i", "firstData"]))
        }), 128))
      ]))
}