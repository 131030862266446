
import {
  computed,
	defineComponent,
  watch,
} from 'vue';
import sendWS from '@/websocket';
import { useStore } from '@/store';
import TheTopMenu from '@/components/TheTopMenu.vue';

export default defineComponent({
	components: {
		TheTopMenu,
	},
	setup() {
		const store = useStore();

		const connectionStatus = computed(() => (store.state.main.connectionStatus));

		sendWS({
			event: 'ping',
		});

		watch(connectionStatus, (newValue) => {
			if (newValue) {
				setTimeout(() => {
					sendWS({ event: 'role', data: 'admin' });
				}, 500);
				setTimeout(() => {
					sendWS({ event: 'getFirstData' });
				}, 1000);
			}
		}, {
			immediate: true,
		});

		const testConnect = () => {
			sendWS({ event: 'getFirstData' });
		};

		return {
			testConnect,
			connectionStatus,
		};
	},
});
