import type { Module } from 'vuex';
import type { State } from '@/store/types';
import { MainState } from './types';
import mainState from './state';
import mainMutations from './mutations';

const mainModule: Module<MainState, State> = {
  state: mainState,
  mutations: mainMutations,
};

export default mainModule;
