import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18bcecce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "top-menu" }
const _hoisted_2 = { class: "top-menu__title" }
const _hoisted_3 = { class: "top-menu__container-button" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "result-link__container"
}
const _hoisted_6 = {
  key: 1,
  class: "result-link__container"
}
const _hoisted_7 = {
  key: 2,
  class: "result-link__container"
}
const _hoisted_8 = {
  key: 3,
  class: "result-link__container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listVariant, (v) => {
        return (_openBlock(), _createElementBlock("div", {
          key: v.id,
          class: _normalizeClass(["top-menu__container", v.id])
        }, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(v.text), 1),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list[v.id], (b) => {
              return (_openBlock(), _createElementBlock("button", {
                key: b,
                class: _normalizeClass(["button-select", (_ctx.selectSort.find((el) => (el.variant === v.id && el.code === b))) ? 'select' : '']),
                onClick: ($event: any) => (_ctx.clickButton(v.id, b))
              }, _toDisplayString(b), 11, _hoisted_4))
            }), 128))
          ])
        ], 2))
      }), 128)),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.reloadData && _ctx.reloadData(...args)))
      }, "Обновить список")
    ]),
    (!_ctx.firstLoadData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, " Для того, чтобы увидеть список сессий выберете интересуемые события "))
      : (_ctx.nowLoadData)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, " Загрузка данных "))
        : (_ctx.sortResult.length === 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, " Нет данных "))
          : (_openBlock(), _createElementBlock("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortResult, (id) => {
                return (_openBlock(), _createBlock(_component_router_link, {
                  key: id,
                  to: { name: 'Home', params: { id } }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(id), 1)
                  ]),
                  _: 2
                }, 1032, ["to"]))
              }), 128))
            ]))
  ], 64))
}