/* eslint-disable import/prefer-default-export */
/* eslint-disable no-shadow */
enum MainMutationsNames {
  SetConnectionStatus = 'SET_CONNECTION_STATUS',
	SetEvent = 'SET_EVENT',
	ClickSort = 'CLICK_SORT',
	SetNowLoad = 'SET_NOW_LOAD',
	SetSortData = 'SET_SORT_DATA',
	SetItemLoad = 'SET_ITEM_LOAD',
	SetItemData = 'SET_ITEM_DATA',
}

export {
	MainMutationsNames,
};
