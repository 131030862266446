import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "item__line" }
const _hoisted_2 = {
  key: 0,
  class: "item__first-data"
}
const _hoisted_3 = {
  key: 1,
  class: "item__first-data-plus"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_json_viewer = _resolveComponent("json-viewer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["item__title", _ctx.classTitle])
    }, [
      (!_ctx.i)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.printData), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_3, "+" + _toDisplayString(_ctx.printData), 1)),
      _createTextVNode(" " + _toDisplayString(_ctx.line.event), 1)
    ], 2),
    (_ctx.json)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_json_viewer, {
            value: _ctx.json,
            boxed: "",
            sort: ""
          }, null, 8, ["value"])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.line.value), 1))
  ]))
}