import { createStore, Store as VuexStore } from 'vuex';
import type { State } from '@/store/types';
import mainModule from './modules/main';
import { MainMutations } from './modules/main/types';
import { MainMutationsNames } from './modules/main/names';

export const store = createStore<State>({
  modules: {
    main: mainModule,
  },
});

export type AllMutations = MainMutations;
// export type AllActions = BlackjackActions;

export type Store = Omit<VuexStore<State>, 'getters' | 'commit' | 'dispatch'> &
	{
		commit<K extends keyof AllMutations, P extends Parameters<AllMutations[K]>[1]>(
			key: K,
			payload: P,
		): ReturnType<AllMutations[K]>;
	}
	// &
	// {
	// 	dispatch<K extends keyof AllActions, P extends Parameters<AllActions[K]>[1]>(
	// 		key: K,
	// 		payload: P,
	// 	): ReturnType<AllActions[K]>;
	// };

export function useStore(): Store {
  return store as Store;
}

export {
	MainMutationsNames,
};
