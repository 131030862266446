/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { MainMutationsNames, useStore } from '@/store';
import { WsFromServerVariant } from '@/types/websocket';

const store = useStore();

let ws: WebSocket | undefined;
let activeAttempt = false;
function startConnect() {
  return new Promise<void>((resolve) => {
    activeAttempt = true;
    try {
      ws = new WebSocket(process.env.VUE_APP_SERVER);

      ws.onmessage = async (message) => {
        const m = JSON.parse(message.data) as WsFromServerVariant;
        console.log(m);

				switch (m.event) {
					case ('firstData'):
						store.commit(MainMutationsNames.SetEvent, m);
						break;
					case ('getList'):
						store.commit(MainMutationsNames.SetSortData, m.last50);
						break;
					case ('getItem'):
						store.commit(MainMutationsNames.SetItemData, m.result);
						break;
					default:
				}
      };

      ws.onclose = () => {
				store.commit(MainMutationsNames.SetConnectionStatus, false);
        activeAttempt = false;
				resolve();
      };

      ws.onopen = () => {
        store.commit(MainMutationsNames.SetConnectionStatus, true);

				// eslint-disable-next-line no-use-before-define
				setTimeout(pingFunc, 0);
        activeAttempt = false;

        resolve();
      };
    } catch {
      resolve();
      activeAttempt = false;
    }
  });
}

const sendWS = async (data: any): Promise<void> => {
  if (!activeAttempt && (typeof ws === 'undefined' || ws.readyState !== 1)) await startConnect();

	if (ws && ws.readyState === 1) {
		ws.send(JSON.stringify(data));
  } else if (data.type !== 'ping') {
		setTimeout(sendWS, 500, data);
	}
};

const pingFunc = () => {
  sendWS({
    event: 'ping',
  });

	setTimeout(pingFunc, 20000);
};

export default sendWS;
