import { EVENT_TO_SDK_VARIANT, EVENT_TYPE_VARIANT } from '@/const';
import type { EventToSdkVariant, EventTypeVariant } from '@/types';
import type { MutationTree } from 'vuex';
import { MainMutationsNames } from './names';
import type { MainMutations, MainState } from './types';

const mainMutations: MutationTree<MainState> & MainMutations = {
  [MainMutationsNames.SetConnectionStatus](state, payload) {
    state.connectionStatus = payload;
  },
	[MainMutationsNames.SetEvent](state, payload) {
		const getType = (event: string): EventTypeVariant => {
			if (event.includes('media-')) return 'media';
			if (event.includes('VPAID-')) return 'vpaid';
			if (event.includes('ut-player-')) return 'player';
			if (EVENT_TO_SDK_VARIANT.includes(event as EventToSdkVariant)) {
				return 'toSdk';
			}

			return 'system';
		};

		EVENT_TYPE_VARIANT.forEach((el) => {
			state.event[el] = [];
		});

		payload.eventAll.forEach((e) => {
			state.event[getType(e)].push(e);
		});
		state.idAll = payload.idAll;
		state.errorCodeAll = payload.errorCodeAll;
  },
	[MainMutationsNames.ClickSort](state, { variant, code }) {
    const findIndex = state.selectSort.findIndex((el) => (el.variant === variant && el.code === code));
			if (findIndex === -1) {
				state.selectSort.push({
					variant,
					code,
				});
			} else {
				state.selectSort = state.selectSort.filter((el) => !(el.variant === variant && el.code === code));
			}
  },
	[MainMutationsNames.SetNowLoad](state, payload) {
    state.nowLoadData = payload;
	},
	[MainMutationsNames.SetSortData](state, payload) {
		state.firstLoadData = true;
    state.nowLoadData = false;
		state.sortResult = payload;
	},
	[MainMutationsNames.SetItemLoad](state, payload) {
    state.itemLoad = payload;
	},
	[MainMutationsNames.SetItemData](state, payload) {
    state.itemLoad = false;
		state.itemData = payload;
	},
};

export default mainMutations;
