/**
 * Основной блок
 */
 export const EVENT_VARIANT_MAIN = [
	'Impression',
	'Error',
] as const;

/**
 * TrackingEvents
 */
export const EVENT_VARIANT_TRACKINGS_EVENTS = [
	'creativeView',
	'start',
	'firstQuartile',
	'midpoint',
	'thirdQuartile',
	'complete',
	'mute',
	'unmute',
	'pause',
	'resume',
	'fullscreen',
	'exitFullscreen',
	'close',
	'acceptInvitation',
	'expand',
	'collapse',
	'closeLinear',
	'skip',
] as const;

/**
 * Extensions
 */
export const EVENT_VARIANT_EXTENSIONS = [
	'second2',
	'eventFetchError',
] as const;

/**
 * Linear VideoClicks
 */
export const EVENT_VARIANT_LINEAR_VIDEO_CLICKS = [
	'ClickThrough',
	'ClickTracking',
	'CustomClick',
] as const;

export const EVENT_VARIANT_SYSTEM_EVENT = [
	'info',
] as const;

export const EVENT_MAIN_VARIANT = [
	'finish',
	'finish_promise',
] as const;

export const EVENT_TO_SDK_VARIANT = [
	...EVENT_VARIANT_MAIN,
	...EVENT_VARIANT_TRACKINGS_EVENTS,
	...EVENT_VARIANT_EXTENSIONS,
	...EVENT_VARIANT_LINEAR_VIDEO_CLICKS,
	...EVENT_VARIANT_SYSTEM_EVENT,
] as const;

export const EVENT_TYPE_VARIANT = [
	'system',
	'player',
	'vpaid',
	'toSdk',
	'media',
] as const;
